import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Components/Sidebar/Sidebar";
import constants from "./Constants";
import Context from "./Context";

const Body = ({ children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contextValues = React.useContext(Context);
  const authed = contextValues.checkAdminLoginStatus();
  return (
    <div className="flex w-full h-screen text-black">
      {
      location.pathname === constants.route.Signup ||
      location.pathname === constants.route.UserVerification ||
      location.pathname === constants.route.Login ||
      location.pathname === constants.route.ForgotPassword ||
      location.pathname === constants.route.EmailVerification ||
      location.pathname === constants.route.pageNotFound ||
      location.pathname === constants.route.configuration ||
      queryParams.get("mode") === "full" ? null : (
        authed && <Sidebar />
      )}
      <div className="w-full bg-backgroundColor overflow-hidden">{children}</div>
    </div>
  );
};

export default Body;
