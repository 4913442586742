import { memo } from "react";
import Input from "./Input";
import SelectField from "./SelectField";
import Textarea from "./Textarea";

const CommonField = ({
  titleText,
  name,
  value,
  onChange,
  error,
  selectfield,
  options,
  textarea,
  rows,
  isMulti,
  type,
  className,
  copyText,
  selectPlaceholder,
  isDisabled,
  ...filds
}) => {
  return (
    <div className={`h-fit ${className}`}>
      {titleText && (
        <div className="flex justify-start items-center text-xs capitalize mb-2 font-medium">
          {titleText}
          {error && error !== "second" && error !== "minute" && error !== "hour" && error !== "day_of_month" && (
            <span className="text-red-900 text-xs">*</span>
          )}
        </div>
      )}
      {selectfield ? (
        <SelectField
          {...filds}
          placeholder={selectPlaceholder}
          value={value}
          options={options}
          onChange={onChange}
          isMulti={isMulti}
          isDisabled={isDisabled}
          closeMenuOnSelect={isMulti ? false : true}
        />
      ) : textarea ? (
        <Textarea {...filds} name={name} rows={rows} value={value} onChange={onChange} error={error} />
      ) : (
        <Input {...filds} name={name} value={value} disabled={isDisabled} onChange={onChange} error={error} type={type} copyText={copyText} />
      )}
    </div>
  );
};

export default memo(CommonField);
