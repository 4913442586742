import { memo } from "react";
import { useLocation } from "react-router";
import { getLocalVariables } from "../utils/utils";
import constants from "../Constants";

const LazyLoading = () => {
  const location = useLocation();
  const sidebarAction = JSON.parse(getLocalVariables("SIDEBAR_ACTION")) ?? true;
  return (
    <div
      className={`flex items-center justify-center h-screen ${
        window.location.href.includes(constants.route.flowCreation) ||
        location?.pathname?.includes(constants.route.Login) ||
        !getLocalVariables("ADMIN_TOKEN")
          ? `w-full`
          : sidebarAction
          ? `w-[calc(100%-0rem)]`
          : `w-[calc(100%-4.2rem)]`
      } absolute top-0 bg-white bg-opacity-5 backdrop-blur-sm z-9999`}
    >
      <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-r-4 border-inputOrange" />
    </div>
  );
};

export default memo(LazyLoading);
