import React, { memo, useState } from "react";

const Textarea = ({ placeholder, name, value, onChange, rows, error }) => {
  const [empty, setEmpty] = useState(false);

  React.useEffect(() => {
    if (value != undefined) {
      setEmpty(true);
    }
  }, [value]);

  return (
    <div className="flex flex-col">
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        id="message"
        className={`block px-10 py-8 w-full text-xs rounded-[4px] border ${
          value === "" && error && empty ? "border-red-500" : "border-d9d9d9"
        }  focus:outline-none ${value === "" && empty && error ? "focus:border-red-500" : "focus:border-inputOrange border-1"} `}
        rows={rows}
        placeholder={placeholder}
      />
      <div className="text-red-800  ">{value === "" && empty && error ? "Please enter a message" : null}</div>
    </div>
  );
};

export default memo(Textarea);
