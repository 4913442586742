import React from "react";
import { BrowserRouter } from "react-router-dom";
import Route from "./Route";
import { Provider } from "./Context";
import Loading from "./Widget/Loading";
import { ConfigProvider } from "antd";
import TestconnectionModal from "./Widget/TestconnectionModal";
import Body from "./Body";

const App = () => {
  return (
    <BrowserRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#007eff",
        },
        components: {
          Button: {
            primaryColor: "#007eff",
            defaultBg: "red",
            defaultHoverColor: "#007eff",
            defaultHoverBorderColor: "#007eff",
            defaultActiveBorderColor: "#007eff",
            defaultActiveColor: "#007eff !important",
          },
          Select: {
            zIndexPopup: 9999,
          },
        },
      }}
    >
     <Provider>
     {/* <Sidebar/> */}
     <Body>
     <Loading />
     <Route />
     <TestconnectionModal />
     </Body>
     </Provider>
     </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
