import dayjs from 'dayjs';
import { getLocalVariables } from "./utils";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('en');
dayjs.extend(relativeTime);

const qs = require("qs");

export const dashboardLakehoseChartQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:asc`,
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["createdAt"],
      populate: {
        spark_cluster: {
          populate: {
            master_node: {
              fields: ["status"],
            },
          },
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.lakehouseDate && {
          createdAt: {
            $between: [
              dayjs(props?.lakehouseDate?.startDate).format("YYYY-MM-DDT00:00:00"),
              dayjs(props?.lakehouseDate?.endDate).format("YYYY-MM-DDT23:59:59"),
            ],
          },
        }),
      },
    },

    {
      encodeValuesOnly: true,
    }
  );

export const dashboardLakehoseQuery = () =>
  qs.stringify(
    {
      sort: "createdAt:asc",
      pagination: {
        start: 0,
        limit: 5,
      },
      fields: ["name", "description", "createdAt"],
      populate: {
        spark_cluster: {
          populate: {
            master_node: {
              fields: ["status"],
            },
          },
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        spark_cluster: {
          master_node: {
            status: {
              $eq: "online",
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const dashboardFlowChartQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:asc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["createdAt", "status"],
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.flowDate && {
          createdAt: {
            $between: [
              dayjs(props?.flowDate?.startDate).format("YYYY-MM-DDT00:00:00"),
              dayjs(props?.flowDate?.endDate).format("YYYY-MM-DDT23:59:59"),
            ],
          },
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const dashboardScheduledQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:asc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["createdAt", "status"],
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.scheduleJobDate && {
          createdAt: {
            $between: [
              dayjs(props?.scheduleJobDate?.startDate).format("YYYY-MM-DDT00:00:00"),
              dayjs(props?.scheduleJobDate?.endDate).format("YYYY-MM-DDT23:59:59"),
            ],
          },
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const lakehouseQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      fields: [
        "id",
        "name",
        "createdAt",
        "description",
        "storage_location",
        "cloud_provider",
        "executor",
        "location",
        "master_node",
        "worker_node",
        "min_worker_nodes",
        "master_url",
        "max_worker_nodes",
        "worker_idle_time",
        "cluster_idle_time",
        "path",
        "description",
      ],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
        spark_cluster: {
          populate: {
            master_node: {
              fields: ["status", "creation_error"],
            },
          },
        },
        configure: {
          fields: ["id"],
        },
      },

      pagination: {
        ...(props?.page && {
          page: props?.page ?? 1,
          pageSize: props?.rowsPerPage ?? 18,
        }),
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              description: {
                $contains: props?.search,
              },
            },
            {
              status: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const lakehouseOptionQuery = () =>
  qs.stringify(
    {
      sort: "createdAt:asc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["id", "name"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const flowTableDataQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:${props?.sort ?? "desc"}`,
      ...(props?.limit
        ? {
            pagination: {
              start: 0,
              limit: props?.limit,
            },
          }
        : {
            pagination: {
              page: props?.page,
              pageSize: props?.rowsPerPage,
            },
          }),
      fields: ["name", "description", "createdAt", "status", "id"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              status: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const findFlowForFlowChart = () =>
  qs.stringify(
    {
      fields: ["canvas", "name", "status", "id"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getComponentForFlowChartQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["id", "name", "component_id", "properties", "status", "ingress", "egress"],
      ...(props?.id && {
        filters: {
          flow: {
            id: {
              $in: props.id,
            },
          },
        },
      }),
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getPoliciesQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      fields: ["id", "name", "status", "description", "createdAt", "auditlogging"],
      populate: {
        users_permissions_user: {
          fields: ["username"],
        },
      },
      filters: {
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              description: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const editPolicyQuery = () =>
  qs.stringify(
    {
      fields: ["id", "name", "description", "status", "auditlogging", "allow_condition", "deny_condition"],
    },
    { encodeValuesOnly: true }
  );

export const getGroupsQueryInPolicy = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id", "groupname"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getUsersQueryInPolicy = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id", "username"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getGroupsQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,

      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },

      fields: ["id", "groupname", "description", "createdAt"],
      populate: {
        users_permissions_users: {
          fields: ["id", "username"],
        },
        users_permissions_user: {
          fields: ["username"],
        },
      },
      ...(props?.search && {
        filters: {
          $or: [
            {
              groupname: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        },
      }),
    },
    {
      encodeValuesOnly: true,
    }
  );

export const userNameQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id", "username"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const savedQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      fields: ["id", "name", "createdAt", "description", "query"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },

      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              query: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const queryHistory = (props) =>
  qs.stringify(
    {
      sort: `createdAt:desc`,
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      fields: ["id", "query", "status", "execution_start_time", "execution_end_time", "error_if_failed", "createdAt"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      ...(props?.search && {
        filters: {
          $or: [
            {
              query: {
                $contains: props?.search,
              },
            },
            {
              status: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        },
      }),
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getSavedQueryById = () =>
  qs.stringify(
    {
      fields: ["id", "query"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getSavedQueryId = (props) =>
  qs.stringify(
    {
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  export const getHistoryQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      fields: ["id", "createdAt","type", "access_by","execution_start_time","execution_end_time","status","query"],
      populate: {
        history: {
          fields: ["id", "name"],
        },
      },
      filters: {
        // type: {
        //   $in: props?.type,
        // },
        ...(props?.date && {
          createdAt: {
            $between: [
              props?.date?.startDate?.format("YYYY-MM-DDT00:00:00") ?? dayjs().subtract(1, "months").startOf("day").format("YYYY-MM-DDT00:00:00"),
              props?.date?.endDate?.format("YYYY-MM-DDT23:59:59") ?? dayjs().endOf("day").format("YYYY-MM-DDT23:59:59"),
            ],
          },
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const cloudAccountQuery = (props) =>
  qs.stringify(
    {
      fields: ["provider_type", "settings", "id"],
      pagination: {
        start: 0,
        limit: -1,
      },
      filters: {
        provider_type: {
          $eq: props?.name,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const settingConfigurationQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "icon", "form", "id"],
      filters: {
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const commonFormConfigurationQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "icon", "id"],
      filters: {
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const lakehouseCreationFormQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["form"],
      filters: {
        name: {
          $eq: props?.name,
        },
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const flowComponentQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "type", "icon", "ports", "flow_component_category", "id"],
      filters: {
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const flowComponentFieldQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["form"],
      filters: {
        name: {
          $eq: props?.name,
        },
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const settingConnectorsConfigureQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      fields: ["name", "configure", "type", "id"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        name: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const configureOptionQuery = () =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["configure", "id", "type"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const configureLakehouseOptionQuery = () =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["configure", "id", "type"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const scheduleJobTableQuery = (props) =>
  qs.stringify(
    {
      sort: `createdAt:${props?.sort ?? "desc"}`,
      fields: ["name", "expression", "job_type", "lab_job_id", "notebook", "createdAt", "status", "id"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      ...(props?.limit
        ? {
            pagination: {
              start: 0,
              limit: props?.limit,
            },
          }
        : {
            pagination: {
              page: props?.page,
              pageSize: props?.rowsPerPage,
            },
          }),
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      populate: "*",
    },
    {
      encodeValuesOnly: true,
    }
  );

export const lakehouseRegisterQuery = () =>
  qs.stringify(
    {
      fields: ["name"],
      populate: {
        spark_cluster: {
          populate: {
            master_node: {
              fields: ["settings", "status", "creation_error"],
            },
            worker_nodes: {
              fields: ["settings", "status", "creation_error"],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const findLakehouseWithNameQuery = (props) =>
  qs.stringify(
    {
      filters: {
        name: props?.name,
      },
      fields: [
        "name",
        "storage_location",
        "cloud_provider",
        "executor",
        "location",
        "master_node",
        "worker_node",
        "min_worker_nodes",
        "master_url",
        "max_worker_nodes",
        "worker_idle_time",
        "cluster_idle_time",
        "path",
        "description",
      ],
      populate: {
        spark_cluster: {
          populate: {
            master_node: {
              fields: ["status"],
            },
          },
        },
        configure: {
          populate: {
            fields: ["id"],
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getUsers = (props) =>
  qs.stringify(
    {
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id", "username"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const reportsQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      fields: ["name", "id"],
      populate: {
        users_permissions_user: {
          fields: ["username"],
        },
        saved_query: {
          fields: ["query"],
        },
        report_folder: {
          fields: ["name"],
        },
      },
      filters: {
        report_folder: {
          id: {
            $eq: props?.folderId,
          },
        },
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const reportsDashboardQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "id", "selected_chart", "properties", "source", "notebook", "api"],
      populate: {
        users_permissions_user: {
          fields: ["username"],
        },
        saved_query: {
          fields: ["name", "query"],
        },
        report_folder: {
          fields: ["name"],
        },
      },
      filters: {
        report_folder: {
          id: {
            $eq: props?.folderId,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const reportFolderForDashboardQuery = () =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      sort: "createdAt:desc",
      fields: ["name"],
      populate: {
        report_charts: {
          fields: ["name"],
        },
      },
      filters: {
        report_charts: {
          name: {
            $notNull: true,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const reportFolderQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      fields: ["name", "description", "createdAt"],
      populate: {
        users_permissions_user: {
          fields: ["username"],
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const findReportQuery = () =>
  qs.stringify(
    {
      fields: ["name", "selected_chart", "properties", "id", "source", "notebook", "api"],
      populate: {
        saved_query: {
          fields: ["name", "query", "id"],
        },
        report_folder: {
          fields: ["name"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const queryChart = (props) =>
  qs.stringify(
    {
      sort: `name:asc`,
      fields: ["id", "name", "thumbnail", "category_type"],
      ...(props?.search && {
        filters: {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              category_type: {
                $contains: props?.search,
              },
            },
          ],
        },
      }),
    },
    {
      encodeValuesOnly: true,
    }
  );

export const findChartQuery = (props) =>
  qs.stringify(
    {
      fields: ["id", "name", "properties", "category_type"],
      ...(props?.name && {
        filters: {
          category_type: props?.name,
        },
      }),
    },
    {
      encodeValuesOnly: true,
    }
  );

export const savedOptionQuery = () =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
    },
    {
      fields: ["id", "name", "query"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const reportDashboardsQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      sort: "createdAt:desc",
      fields: ["name", "createdAt"],
      populate: {
        users_permissions_user: {
          fields: ["username"],
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const chartDashboardQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "selected_chart", "properties", "source", "notebook", "api"],
      populate: {
        saved_query: {
          fields: ["name", "query", "id"],
        },
      },

      filters: {
        ...(props?.search && {
          name: {
            $contains: props?.search,
          },
        }),
        properties: {
          $notNull: true,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const chartDashboardCustomizeQuery = () =>
  qs.stringify(
    {
      fields: ["config", "name", "start_date", "end_date"],
    },
    {
      encodeValuesOnly: true,
    }
  );

export const userMeQuery = () =>
  qs.stringify(
    {
      fields: ["id", "username", "email", "confirmed", "last_name"],
      populate: {
        role: {
          fields: ["type"],
        },
        // enterprise: {
        //   fields: ["id", "name", "website", "contact_number"],
        //   populate: {
        //     cloud_account_settings: {
        //       fields: ["provider_type"],
        //     },
        //   },
        // },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getAIStudioQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      pagination: {
        page: props?.page,
        pageSize: props?.rowsPerPage,
      },
      fields: ["id", "name", "description", "createdAt"],
      populate: {
        users_permissions_user: {
          fields: ["username", "id"],
        },
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getAIStudioFindOneQuery = () =>
  qs.stringify(
    {
      fields: ["id", "name", "canvas"],
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const studioComponentQuery = (props) =>
  qs.stringify(
    {
      pagination: {
        start: 0,
        limit: -1,
      },
      fields: ["name", "type", "icon", "ports", "flow_component_category", "description", "id"],
      filters: {
        type: {
          $eq: props?.type,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getUsersQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      ...(props?.limit && {
        pagination: {
          start: 0,
          limit: props?.limit,
        },
      }),
      fields: ["id", "username", "email", "confirmed", "createdAt", "blocked","confirmationLink"],
      populate: {
        role: {
          fields: ["id", "name"],
        },
      },
      filters: {
        id: {
          $ne: getLocalVariables("USER_DATA")?.id ?? props?.id,
        },
        ...(props?.search && {
          $or: [
            {
              username: {
                $contains: props?.search,
              },
            },
            {
              email: {
                $contains: props?.search,
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

export const getUsersByIDQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      fields: ["id", "confirmed", "email", "username"],
      filters: {
        id: {
          $eq: props?.id,
        }
      },
    }
  );

export const getUploadedImagesQuery = (props) =>
  qs.stringify(
    {
      filters: {
        id: {
          $in: props?.images,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  export const settingsQuery = (props) =>
  qs.stringify(
    {
      sort: "createdAt:desc",
      fields: [
        "id",
        "createdAt",
        "table",
        "catalogs",
        "namespace",
        "type"
      ],
      populate: {
        users_permissions_users: {
          fields: ["id","username"],
        },
        user_groups:{
          fields: ["id","groupname"]
        }
      },
 
      pagination: {
        start: 0,
        limit: -1,
      },
      filters: {
        enterprise: {
          id: {
            $eq: getLocalVariables("USER_DATA")?.enterprise,
          },
        },
        ...(props?.search && {
          $or: [
            {
              name: {
                $contains: props?.search,
              },
            },
            {
              users_permissions_user: {
                username: {
                  $contains: props?.search,
                },
              },
            },
          ],
        }),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  export function getLastSegment(activeMenu) {
    if (activeMenu && activeMenu.active) {
      const segments = activeMenu.active.split('/');
      const lastSegment = segments[segments.length - 1];
   
      if (activeMenu.active === '/data-dictionary/schema') {
        return 'database_schema';
      } else {
        return lastSegment;
      }
    }
    return '';
   }