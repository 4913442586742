import React, { useEffect } from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import constants from "./Constants";
import LazyLoading from "./Widget/LazyLoading";
import Context from "./Context";
import { getLocalVariables } from "./utils/utils";

const AddDataServices = React.lazy(() => import("./Components/DataSource/Newservice"));
const Policies = React.lazy(() =>  import("./Components/SecurityPolicy/TablePolicies"));
const AddPolicy = React.lazy(() => import("./Components/SecurityPolicy/AddPolicy"));
const Group = React.lazy(() => import("./Components/UserMangement/Group"));
const DataCatalog = React.lazy(() => import("./Components/Catalog/DataCatalog"));
const Dashboard = React.lazy(() => import("./Components/Dashboard/Dashboard"));
const Login = React.lazy(() => import("./Components/Login/Login"));
const ForgotPassword = React.lazy(() => import("./Components/ForgotPassword/ForgotPassword"));
const Signup = React.lazy(() => import("./Components/Signup/Signup"));
const EmailVerification = React.lazy(() => import("./Components/EmailVerify/EmailVerification"));
const Settings = React.lazy(() => import("./Components/Settings/Settings"));
const PageNotFound = React.lazy(() => import("./Widget/PageNotFound"));
const UserVerification = React.lazy(() => import("./Components/UserVerification/UserVerification"));
const DataDictionary = React.lazy(()=> import("./Components/DataDictionary/DataDictionary"));
const Configuration = React.lazy(()=> import("./Components/Login/Configuration"));

const SuspenseWrapper = ({ component }) => (
  <React.Suspense fallback={<LazyLoading />}>{component}</React.Suspense>
);

const Router = () => {
  const contextValues = React.useContext(Context);
  useEffect(() => {
    const checkSuperAdminExist = async () => {
      await contextValues?.checkSuperAdminExist();
    };
    checkSuperAdminExist();
  }, []);

  return (
    <Routes>
      <Route path={constants.route.default} element={<Navigate to={constants.route.Dashboard} />} />
      <Route path={constants.route.settings} element={<RequireAuth><Settings /></RequireAuth>} />
      <Route path={constants.route.configuration} element={<RequireAuth><Configuration /></RequireAuth>} />
      <Route path={constants.route.Service} element={<RequireAuth><AddDataServices /></RequireAuth>} />
      <Route path={constants.route.PoliciesTable} element={<RequireAuth><Policies /></RequireAuth>} />
      <Route path={constants.route.PoliciesTable + "/add-policy"} element={<RequireAuth><AddPolicy /></RequireAuth>} />
      <Route path={constants.route.Group} element={<RequireAuth><Group /></RequireAuth>} />
      <Route path={constants.route.PoliciesTable + "/edit-policy/:id"} element={<RequireAuth><AddPolicy /></RequireAuth>} />
      <Route path={constants.route.Catalog} element={<RequireAuth><DataCatalog /></RequireAuth>} />
      <Route path={constants.route.Dashboard} element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route exact path={constants.route.DataDictionary} element={<RequireAuth><DataDictionary /></RequireAuth>} />
      <Route path={constants.route.Login} element={<SuspenseWrapper component={<Login />} />} />
      <Route path={constants.route.UserVerification} element={<SuspenseWrapper component={<UserVerification />} />} />
      <Route path={constants.route.ForgotPassword} element={<SuspenseWrapper component={<ForgotPassword />} />} />
      <Route path={constants.route.Signup} element={<SuspenseWrapper component={<Signup />} />} />
      {/* <Route path={"*"} element={<Navigate to={constants.route.pageNotFound} />} /> */}
      <Route path={constants.route.pageNotFound} element={<SuspenseWrapper component={<PageNotFound />} />} />
      <Route path={constants.route.EmailVerification} element={<SuspenseWrapper component={<EmailVerification />} />} />
    </Routes>
  );
};

export default Router;

const RequireAuth = ({ children }) => {
  const contextValues = React.useContext(Context);
  const authed = contextValues.checkAdminLoginStatus();
  const location = useLocation();
  const pagePath = location.pathname;
  const route = Object.values(constants.route).filter((value) => value !== "/");
  const pageExists = route.some((item) => pagePath.includes(item.substring(1)));
  const userData = getLocalVariables("USER_DATA");

  if (!authed) {
    return (
      <Navigate to={constants.route.Login} state={{ from: location }} replace />
    );
  }
  if (!pageExists) {
    return <Navigate to={constants.route.pageNotFound} />;
  }
  if (
    userData?.role?.type != "admin" &&
    pagePath?.includes(constants.route.security)
  ) {
    return <Navigate to={constants.route.pageNotFound} />;
  }
  if (pagePath?.includes(constants.route.UserVerification)) {
    return <Navigate to={constants.route.UserVerification} />;
  }
  return <SuspenseWrapper component={children} />;
};
