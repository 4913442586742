import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import constants from "./Constants";
import Toastify, { showToast } from "./Widget/Toastify";
import { getGroupsQuery, getUsersQuery, getPoliciesQuery, userMeQuery, settingsQuery} from "./utils/Query";
import { getLocalVariables, generateQueryString, setLocalVariables, generateRandomString, modifyString} from "./utils/utils";
import Commonfield from "./Widget/Commonfield";
import { useNavigate, useLocation } from "react-router-dom";
const qs = require("qs");
export const Context = createContext({});

export const Provider = ({ children }, props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [serviceList, setServiceList] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedService, setSelectedService] = useState();
  const [serviceNameValue, setServiceNameValue] = useState();
  const [tableData, setTableData] = useState([]);
  const [metaTableData, setMetaTableData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [dbList, setDBList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [descriptionVal, setDescriptionVal] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(page);
  const [rowsPerPage] = useState(10);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [policyData, setPolicyData] = useState();
  const [userData, setUserData] = useState();
  const [groupData, setGroupData] = useState();
  const [getCatalogs, setGetCatalogs] = useState();
  const [editPolicy, setEditPolicy] = useState();
  const [getCustomNamespace, setGetCustomNamespace] = useState();
  const [selectedCatalogDetails, setSelectedCatalogDetails] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [settingTableData, setSettingTableData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [connData, setConnData] = useState([]);
  const [userPermission, setUserPermission] = useState();
  const [messagingServices, setMessagingServices] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [connectionSteps, setConnectionSteps] = useState([]);
  const [dictionaryDataBckp, setDictionaryDataBckp] = useState([]);
  const [userConnectionData, setUserConnectionData] = useState();


  const location = useLocation();
  const pagePath = location.pathname;
  const route = Object.values(constants.route).filter((value) => value !== "/");
  const pageExists = route.some((item) => pagePath.includes(item.substring(1)));

  const shownErrorStatusSet = new Set();
  const commonErrorShowPopup = (props) => {
    const { error, shoMsg } = props;
    const showError = (message) => {
      showToast(message, "error", "top-center");
    };
    const resetShownErrorStatusSet = () => {
      shownErrorStatusSet.clear();
    };
    if ([409]?.includes(error?.response?.status)) {
      showError(error?.response?.data?.message);
    } else if (shoMsg) {
      showError(shoMsg);
    } else if (!shownErrorStatusSet.has(error?.response?.data?.error?.status)) {
      if (error?.response?.data?.error?.status == 403) {
        showError("Please contact your admin for the permission");
      } else if (error?.response?.data?.error?.status == 401) {
        showError("Unauthorized access is prohibited");
      } else if (
        error?.response?.data?.error?.status == 400 &&
        error?.response?.data?.error?.message ==
          "Email or Username are already taken"
      ) {
        showError("Email or Username are already taken.");
      } else if (
        error?.response?.data?.code == 400 ||
        error?.response?.data?.message
      ) {
        showError(error?.response?.data?.message ?? "Something wrong!");
      } else {
        showError(error?.response?.data?.error?.message ?? "Something wrong!");
      }
      shownErrorStatusSet.add(error?.response?.data?.error?.status);
      setTimeout(resetShownErrorStatusSet, 1000);
    }

    if ([401]?.includes(error?.response?.data?.error?.status)) {
      localStorage.clear();
      navigate(constants.route.Login);
    }
  };

  //fetch user data api
  const fetchUserData = async (props) => {
    if (!props?.noLoader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      const request_data = {
        method: "GET",
        url: props?.query ? constants.url.users + `?${props?.query}` : constants.url.users,
        headers: {
          "Content-Type": "application/json",
          Authorization: props?.token ?? `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        }
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setUserData(response.data);
        if (!props?.noLoader) {
          setLoadingCounter((prevCounter) => prevCounter - 1);
        }
        setLoadingCounter(0);
        return response.data;
      }
    } catch (error) {
      if (!props?.noLoader) {
        setLoadingCounter((prevCounter) => prevCounter - 1);
      }
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //get Group api
  const getGroup = async (props) => {
    const query = qs.stringify(
      {
        sort: `createdAt:${props?.sort ?? "desc"}`,
        ...(props?.limit
          ? {
              pagination: {
                start: 0,
                limit: props?.limit,
              },
            }
          : {
              pagination: {
                page: props?.page,
                pageSize: props?.rowsPerPage,
              },
            }),
        populate: "*",
        filters: {
          ...(props?.status && {
            status: { $eq: props?.status},
          }),
          ...(props?.search && {
            $or: [
              {
                name: {$contains: props?.search},
              },
              {
                description: {$contains: props?.search},
              },
              {
                status: {$contains: props?.search},
              },
            ],
          }),
        },
      },
      {
        populate: "*",
      },
      {
        filters: {},
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.groups + `?${props?.query ?? query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGroupData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoadingCounter(0);
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //get all Catalogs api
  const getAllCatalogs = async (props) => {
    if (!props?.noLoader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      const request_data = {
        method: "GET",
        url: constants?.url?.getCatalogs + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGetCatalogs(response?.data?.data);
        if (!props?.noLoader) {
          setLoadingCounter((prevCounter) => prevCounter - 1);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (!props?.noLoader) {
        setLoadingCounter((prevCounter) => prevCounter - 1);
      }
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //edit policies api
  const getEditPolicy = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.policies}/${props?.id}?${props?.query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setEditPolicy(response?.data?.data);
        setLoadingCounter(0);
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //put policy api
  const putPolicy = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    const putData = {
      name: props.name,
      description: props.description,
      type: props.type,
      auditlogging: props.auditlogging,
      allow_condition: props.allow_condition,
      deny_condition: props.deny_condition,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.policies}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: putData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("Policy updated successfully", "success", "top-center");
        setLoadingCounter(0);
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //delete policy api
  const deletePolicy = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.policies}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        showToast("Policy deleted successfully", "success", "top-center");
        GetPolicies({ query: getPoliciesQuery({ page: 1, rowsPerPage: 16 }) });
      }
    } catch (error) {
      commonErrorShowPopup({ error });
    }
  };

  const deleteSource = async (id) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.addDBService}/${id}?hardDelete=true&recursive=true`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const delete_source = await axios(user_req_obj);
      if ([200, 201].includes(delete_source.status) && delete_source.data) {
        showToast("Database Service deleted successfully", "success", "top-center");
        await getAllCatalogs({ limit: 1000, include: "non-deleted" });
        setLoadingCounter(0);
        return delete_source;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  const deleteTestcase = async (id) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.TestcaseUrl}/${id}?hardDelete=true&recursive=true`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        showToast("Testcase deleted successfully","success","top-center");
        await TestcaseData();
        setLoadingCounter(0);
        return user_response;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  // create policy api
  const createPolicy = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    const parseUserData = getLocalVariables("USER_DATA");
    const createData = {
      name: props.name,
      description: props.description,
      status: props.status,
      type: props.type,
      auditlogging: props.auditlogging,
      allow_condition: props.allow_condition,
      deny_condition: props.deny_condition,
      users_permissions_user: parseUserData?.id,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.policies}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("Policy created successfully", "success", "top-center");
        setLoadingCounter(0);
        return elements_response;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  // get policy data
  const GetPolicies = async (props) => {
    const query = qs.stringify(
      {
        sort: `createdAt:${props?.sort ?? "desc"}`,
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        filters: {
          ...(props?.activeTab && {
            type: {$eq: props?.activeTab},
          }),
        },
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.policies}?${props?.query ?? query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setPolicyData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoadingCounter(0);
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //get namespace api
  const customNamespace = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const request_data = {
        method: "GET",
        url: constants.url.getCustomNameSpace + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGetCustomNamespace(response?.data?.data);
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  const getTopics = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const request_data = {
        method: "GET",
        url: constants.url.topics + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        // setGetCustomNamespace(response?.data?.data);
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //get topic names for kafka
  const getTopicName = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const request_data = {
        method: "GET",
        url:constants.url.topics_name + `/${props?.name}?limit=1000&include=non-deleted`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //get column tags
  const getColumnTags = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const request_data = {
        method: "GET",
        url: constants.url.getColumnTags + `?limit=1000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //create custom classification
  const createClassification = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    const req1 = {
      name: props?.data?.classname?.value,
      displayName: "",
      description: `${props?.data?.classname?.value} Tag`,
      mutuallyExclusive: true,
    };
    const req2 = {
      name: props?.data?.tagName,
      displayName: "",
      description: `${props?.data?.tagName} Tag`,
      style: {},
      classification: props?.data?.classname?.label,
    };
    try {
      const classData = {
        method: "POST",
        url: constants.url.createClassification,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(req1),
      };
      const tagData = {
        method: "POST",
        url: constants.url.createColumnTag,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(req2),
      };
      const response = await axios(classData);
      if ([200, 201].includes(response?.status) && response?.data) {
        const tag_response = await axios(tagData);
        if ([200, 201].includes(tag_response?.status) && tag_response?.data) {
          if (loader) {
            setLoadingCounter(0);
          }
          setLoadingCounter(0);
          return {
            classification: response?.data?.data,
            tagData: tag_response?.data,
          };
        }
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //create custom tags under classification
  const createTag = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    const req2 = {
      name: props?.data?.tagName,
      displayName: "",
      description: `${props?.data?.tagName} Tag`,
      style: {},
      classification: props?.data?.classname?.label,
    };

    try {
      const tagData = {
        method: "POST",
        url: constants.url.createColumnTag,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(req2),
      };
      const response = await axios(tagData);
      if ([200, 201].includes(response?.status) && response?.data) {
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //get Tables api
  const getTables = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const config = {
        method: "GET",
        url: constants.url.getTables + generateQueryString(props),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(config);
      if ([200, 201].includes(response?.status) && response?.data) {
        setMetaTableData(response?.data?.data);
        if (loader) {
          setLoadingCounter(0);
        }
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      setLoadingCounter(0);
    }
  };

  //get columns api
  const getColumnsData = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.getColumns}/${props}?fields=columns,testSuite,tags,joins,dataModel,tableConstraints,viewDefinition&limit=1000&include=all`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(elements_req_obj);
      if ([200, 201].includes(response?.status) && response?.data) {
        setColumnsData(response?.data);
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  //get database service list api
  const getDBList = async () => {
    try {
      const request_data = {
        method: "GET",
        url: constants.url.Connectors,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            props?.token ?? getLocalVariables("ADMIN_TOKEN")
          }`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setDBList(response?.data?.data[0]?.attributes?.data);
        setServiceList(response?.data?.data[0]?.attributes?.data);
        return response?.data?.data[0]?.attributes?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  //put user api
  const putUser = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    delete props?.data?.role;
    delete props?.data?.createdAt;
    if (!props?.data?.password) {
      delete props?.data?.password;
    }
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.users}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(props.data),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast(props?.message ?? "User updated successfully","success","top-center");
        fetchUserData({ query: getUsersQuery() });
        setLoadingCounter(0);
        return elements_response;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //create user api
  const createUser = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const elements_req_obj = {
        method: "POST",
        url: constants.url.users,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ ...props, role: 1 }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("User created successfully", "success", "top-center");
        await updateActiveLink(elements_response?.data);
        await fetchUserData({ query: getUsersQuery() });
        setLoadingCounter(0);
        return elements_response;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  const updatePasswordByUser = async (props) => {
    const { data, encryptedValues } = props;
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const elements_req_obj = {
        method: "POST",
        url: constants.url.updatePassword,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${encryptedValues?.jwt_token}`,
        },
        data: JSON.stringify({
          userID: encryptedValues?.userID,
          password: data?.confirm,
          confirmed: true,
        }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("Password updated successfully. You will be redirected to the login page in 3 seconds..","success","top-center");
        if (loader) {
          setLoadingCounter((prevCounter) => prevCounter - 1);
        }
        setLoadingCounter(0);
        return elements_response;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  //delete group api
  const deleteGroup = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.groups}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        showToast("Group deleted successfully", "success", "top-center");
        getGroup({ query: getGroupsQuery({ page: 1, rowsPerPage: 16 }) });
      }
    } catch (error) {
      commonErrorShowPopup({ error });
    }
  };

  const deleteUser = async (id) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.users}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        showToast("User deleted successfully", "success", "top-center");
        fetchUserData({ query: getUsersQuery() });
        setLoadingCounter(0);
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //create group api
  const createGroup = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    const parseUserData = getLocalVariables("USER_DATA");
    const createData = {
      groupname: props.groupname,
      description: props.description,
      users_permissions_users: props?.users_permissions_users?.map((item) => item?.value),
      status: props.status,
      users_permissions_user: parseUserData?.id,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: constants.url.groups,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("Group created successfully", "success", "top-center");
        getGroup({ query: getGroupsQuery({ page: 1, rowsPerPage: 16 }) });
        setLoadingCounter((prevCounter) => prevCounter - 1);
        return elements_response;
      }
    } catch (error) {
      setLoadingCounter((prevCounter) => prevCounter - 1);
      commonErrorShowPopup({ error });
    }
  };

  //put group api
  const putGroup = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    const editData = {
      groupname: props.groupname,
      description: props.description,
      status: props.status,
      users_permissions_users: props?.users_permissions_users,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.groups}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: editData }),
      };
      const elements_response = await axios(elements_req_obj);
      if([200, 201].includes(elements_response.status) && elements_response.data) {
        showToast("Group updated successfully", "success", "top-center");
        getGroup({ query: getGroupsQuery({ page: 1, rowsPerPage: 16 }) });
        setLoadingCounter((prevCounter) => prevCounter - 1);
        return elements_response;
      }
    } catch (error) {
      setLoadingCounter((prevCounter) => prevCounter - 1);
      commonErrorShowPopup({ error });
    }
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name == "ingestAllDatabases") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Rendering Ui form fields for service connection details
  const RenderFields = (fields, fieldErrors, keyValuePairs) => {
    return (
      fields &&
      fields.map((field, index) => {
        const isFieldEmpty = field?.required && !formData[field.name];
        const error = fieldErrors && fieldErrors[field.name]; // Get the error message for the field safely
        let fieldValue = "";
        if (keyValuePairs?.length > 0) {
          // Find the corresponding value in keyValuePairs for the field
          const keyValuePair = keyValuePairs?.find(
            (item) => item.key === field.name
          );
          if (keyValuePair) {
            fieldValue = keyValuePair.value;
          }
        }
        switch (field.type) {
          case "input":
            return (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Commonfield
                  key={index}
                  error={field.required}
                  name={field.name}
                  placeholder={`Enter ${field?.label}`}
                  className="mb-2"
                  titleText={field.label}
                  defaultValue={field.defaultValue}
                  value={
                    formData[field.name] || fieldValue || field.defaultValue
                  }
                  onChange={handleChange}
                />
                {error && (
                  <div className="text-red text-xs">
                    {error || "This field is required."}
                  </div>
                )}
              </div>
            );

          case "dropdown":
            return (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Commonfield
                  key={index}
                  name={field.name}
                  error={field.required}
                  defaultValue={{label: field?.defaultValue,value: field?.defaultValue}}
                  // value={formData[field.name]}
                  className="mb-2"
                  titleText={field.label}
                  options={field?.options.map((val) => {
                    return { value: val, label: val };
                  })}
                  getOptionLabel={(e) => <span>{e.label}</span>}
                  onChange={(selectedOption) =>
                    handleChange({
                      target: {
                        name: field.name,
                        value: selectedOption && selectedOption.value,
                      },
                    })
                  }
                  selectfield
                />
                {error && !formData["DatalakeConfigSource"] && (
                  <div className="text-red text-xs">
                    {error || "This field is required."}
                  </div>
                )}
              </div>
            );

          case "checkbox":
            return (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <input
                  type="checkbox"
                  name={field.name}
                  checked={formData[field.name]}
                  onChange={handleChange}
                />
                <label className="text-xs capitalize font-medium">
                  {field.label}
                </label>
              </div>
            );

          case "password":
            return (
              <div key={index}>
                <Commonfield
                  key={index}
                  name={field.name}
                  error={field.required}
                  placeholder={`Enter ${field?.label}`}
                  className="mb-2"
                  titleText={field.label}
                  value={formData[field.name] || fieldValue}
                  type="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
                {error && (
                  <div className="text-red text-xs">
                    {error || "This field is required."}
                  </div>
                )}
              </div>
            );

          case "conditional":
            const conditionMet =
              field?.condition?.type === "dropdown_value" &&
              formData[field.condition.target] === field.condition.value;
            if (conditionMet) {
              return RenderFields(field.conditionfields);
            }
            return null;

          default:
            return null;
        }
      })
    );
  };

  // get registered database table data api
  const getRegisteredTableData = async (
    params = { limit: rowsPerPage, include: "non-deleted" }
  ) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    const queryString = new URLSearchParams(params).toString();
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.addDBService}?${queryString}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setTableData(response?.data?.data);
        setTotalItems(response?.data?.paging?.total);
        if(loader){
          setLoadingCounter(0);
        }
        return response?.data?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  const getMessagingServices = async (props) => {
    try {
      const request_data = {
        method: "GET",
        url: constants?.url?.messagingService + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setMessagingServices(response?.data?.data);
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //Function for appending form value to payload object
  const appendValueToPayload = (data, values) => {
    Object.keys(values).forEach((key) => {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] === "object" && !Array.isArray(data[key])) {
          appendValueToPayload(data[key], values[key]);
        } else {
          data[key] = values[key];
        }
      } else if (
        data.credentials &&
        data.credentials.gcpConfig &&
        data.credentials.gcpConfig.hasOwnProperty(key)
      ) {
        data.credentials.gcpConfig[key] = values[key];
      } else if (
        data.credentials &&
        data.credentials.gcpImpersonateServiceAccount &&
        data.credentials.gcpImpersonateServiceAccount.hasOwnProperty(key)
      ) {
        data.credentials.gcpImpersonateServiceAccount[key] = values[key];
      } else if (
        data.metastoreConnection &&
        data.metastoreConnection.hasOwnProperty(key)
      ) {
        data.metastoreConnection[key] = values[key];
      } else if (
        data.metastoreConnection &&
        data.metastoreConnection.authType &&
        data.metastoreConnection.authType.hasOwnProperty(key)
      ) {
        data.metastoreConnection.authType[key] = values[key];
      } else if (
        data.metastoreConnection &&
        data.metastoreConnection.sslConfig &&
        data.metastoreConnection.sslConfig.hasOwnProperty(key)
      ) {
        data.metastoreConnection.sslConfig[key] = values[key];
      } else if (
        data.authType &&
        data.authType.awsConfig &&
        data.authType.awsConfig.hasOwnProperty(key)
      ) {
        data.authType.awsConfig[key] = values[key];
      } else if (
        data.configSource &&
        data.configSource.securityConfig &&
        data.configSource.securityConfig.hasOwnProperty(key)
      ) {
        data.configSource.securityConfig[key] = values[key];
      } else if (
        data.connectionOptions &&
        data.connectionOptions &&
        data.connectionOptions.hasOwnProperty(key)
      ) {
        data.connectionOptions[key] = values[key];
      } else if (
        data.authType &&
        data.authType &&
        data.authType.hasOwnProperty(key)
      ) {
        data.authType[key] = values[key];
      } else if (data.awsConfig && data.awsConfig.hasOwnProperty(key)) {
        data.awsConfig[key] = values[key];
      } else if (
        data.connectionDetails &&
        data.connectionDetails.hasOwnProperty(key)
      ) {
        data.connectionDetails[key] = values[key];
      } else if (
        data.oracleConnectionType &&
        data.oracleConnectionType.hasOwnProperty(key)
      ) {
        data.oracleConnectionType[key] = values[key];
      } else if (data.connection && data.connection.hasOwnProperty(key)) {
        data.connection[key] = values[key];
      } else if (data.sslConfig && data.sslConfig.hasOwnProperty(key)) {
        data.sslConfig[key] = values[key];
      }
    });

    // Delete unmatched keys from data.configSource.securityConfig
    data?.configSource &&
      Object.keys(data?.configSource?.securityConfig).forEach((key) => {
        if (!values.hasOwnProperty(key)) {
          delete data.configSource.securityConfig[key];
        }
      });
    return data;
  };

  //get connection response api
  const getConnectionResponse = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const config = {
        method: "GET",
        url: `${constants.url.testConnections}/${props}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(config);
      if ([200, 201].includes(response.status) && response.data) {
        if (loader) {
          setLoadingCounter(0);
        }
        setConnectionSteps(response?.data?.response?.steps);
        await deleteAutoFlowConnection(response?.data?.id);
        await getDBList();
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      if (loader) {
        setLoadingCounter(0);
      }
      setLoadingCounter(0);
      await getDBList();
      commonErrorShowPopup({ error });
    }
  };

  //get connection trigger api
  const connectionTrigger = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const config = {
        method: "POST",
        url: `${constants.url.testConnectionTrigger}/${props}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(config);
      if ([200, 201].includes(response.status) && response.data) {
        await getConnectionResponse(props);
        setLoadingCounter(0);
        return response?.data?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      await getDBList();
      commonErrorShowPopup({ error });
    }
  };

  // Function to check if authType keys have non-empty corresponding values in formData
  const checkAndUpdateAuthType = (parsedResult, formData) => {
    if(parsedResult?.connection?.config?.authType){
      const authTypeKeys = Object.keys(parsedResult?.connection?.config?.authType);
      authTypeKeys?.forEach(key => {
        if (formData[key] && formData[key].trim() !== "") {
          parsedResult.connection.config.authType[key] = formData[key];
        } else {
          delete parsedResult.connection.config.authType[key];
        }
      });
      // If authType object is empty after the updates, delete it
      if (Object.keys(parsedResult?.connection?.config?.authType).length === 0) {
        delete parsedResult.connection.config.authType;
      }
    }
  };

  // test connection api
  const CheckTestConnection = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    const randomChars = generateRandomString(6);
    const service_name = `${
      props?.testData?.serviceName
        ? props?.testData?.serviceName
        : props?.testConn?.service_name
        ? props?.testConn?.service_name
        : serviceNameValue
    }_${randomChars}`;

    let catalogData;
    if (props?.testData) {
      catalogData = {
        name: service_name,
        workflowType: "TEST_CONNECTION",
        request: {
          connection: props?.testData?.connection,
          connectionType: props?.testData?.serviceType,
          serviceType:
            props?.testData?.serviceType === "Kafka" ? "Messaging" : "Database",
        },
      };
    } else if (props?.testConn) {
      const test = appendValueToPayload(props?.testConn?.connection?.config,formData);
      delete test?.fields;
      const result = JSON.stringify(test, null, 2);
      const parsedResult = JSON.parse(result);
      checkAndUpdateAuthType(parsedResult, formData);
      catalogData = {
        name: service_name,
        workflowType: "TEST_CONNECTION",
        request: {
          connection: { config: parsedResult },
          connectionType: props?.testConn?.serviceType,
          serviceType: props?.testConn?.serviceType === "Kafka" ? "Messaging" : "Database",
        },
      };
    } else {
      const test = appendValueToPayload(selectedService?.connection?.config,formData);
      const filteredApiData = { ...selectedService };
      delete filteredApiData.connection.config.fields;
      delete filteredApiData.connection.config.type;
      const result = JSON.stringify(filteredApiData, null, 2);
      const parsedResult = JSON.parse(result);
      parsedResult["connection"]["config"]["type"] = selectedService?.serviceType;
      checkAndUpdateAuthType(parsedResult, formData);
      console.log("parsedResult===>>",parsedResult)
      catalogData = {
        name: service_name,
        workflowType: "TEST_CONNECTION",
        request: {
          connection: parsedResult?.connection,
          connectionType: selectedService?.serviceType,
          serviceType: selectedService?.serviceType === "Kafka" ? "Messaging" : "Database",
        },
      };
    }

    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.testConnections}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(catalogData),
      };
      console.log("formValues===>>",formData)

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        await connectionTrigger(response?.data?.id);
        setLoadingCounter(0);
      } else {
        setLoadingCounter(0);
        return null;
      }
    } catch (error) {
      showToast("Error while testing connections", "error", "top-center");
      await getDBList();
      setLoadingCounter(0);
    }
  };

  //delete connection workflow
  const deleteAutoFlowConnection = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const config = {
        method: "DELETE",
        url: `${constants.url.testConnections}/${props}?hardDelete=true`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(config);
      if ([200, 201].includes(response.status) && response.data) {
        if (loader) {
          setLoadingCounter((prevCounter) => prevCounter - 1);
        }
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //delete ingestion pipelines
  const deleteIngestionPipeline = async (props) => {
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    delete props?.loader;
    try {
      const config = {
        method: "DELETE",
        url: `${constants.url.ingestionPipelines}/${props}?hardDelete=true`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(config);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        showToast("Ingestion pipeline deleted successfully","success","top-center");
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //put db connection details api
  const putDBConnectionDetails = async ({
    databaseDetails,
    method,
    strapi_conn_ID,
  }) => {
    try {
      const dbdetails = {
        method: method,
        url: method === "POST"
            ? `${constants.url.DBConnDetails}`
            : `${constants.url.DBConnDetails}/${strapi_conn_ID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: databaseDetails }),
      };
      const strapi_res = await axios(dbdetails);
      if ([200, 201].includes(strapi_res.status) && strapi_res.data) {
        setLoadingCounter(0);
        navigate(constants.route.Catalog);
        await getDBList();
        return strapi_res?.data;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      await getDBList();
      setLoadingCounter(0);
    }
  };

  //save database service api
  const saveDBService = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    let catalogData;
    let method;
    let url;
    let databaseDetails = {
      username: formData?.username,
      serviceName: serviceNameValue ?? props?.saveConn?.service_name,
      database: formData?.databaseName
        ? formData?.databaseName
        : formData?.database,
      hostPort: formData?.hostPort,
      serviceType: selectedService?.serviceType ?? props?.saveConn?.serviceType,
      password: formData?.password ? btoa(encodeURIComponent(formData?.password)) : "",
      bootstrapServers: formData?.bootstrapServers,
      securityToken: formData?.securityToken,
    };

    if (props?.saveConn) {
      const test = appendValueToPayload(
        props?.saveConn?.connection?.config,
        formData
      );
      delete test?.fields;
      const result = JSON.stringify(test, null, 2);
      const parsedResult = JSON.parse(result);
      checkAndUpdateAuthType(parsedResult, formData);
      if (props?.saveConn?.serviceType !== "Kafka") {
        url = `${constants.url.addDBService}`;
      } else {
        url = `${constants.url.messagingService}`;
      }
      catalogData = {
        name: props?.saveConn?.service_name,
        serviceType: props?.saveConn?.serviceType,
        description: descriptionVal ? descriptionVal : "",
        connection: { config: parsedResult },
      };
      method = "PUT";
      databaseDetails["connection"] = { config: parsedResult };
    } else {
      appendValueToPayload(selectedService?.connection?.config, formData);
      const filteredApiData = { ...selectedService };
      delete filteredApiData.connection.config.fields;
      const result = JSON.stringify(filteredApiData, null, 2);
      const parsedResult = JSON.parse(result);
      checkAndUpdateAuthType(parsedResult, formData);
      catalogData = {
        name: serviceNameValue,
        serviceType: selectedService?.serviceType,
        description: descriptionVal ? descriptionVal : "",
        connection: parsedResult?.connection,
      };
      method = "POST";
      url = `${constants.url.addDBService}`;
      databaseDetails["connection"] = parsedResult?.connection;
      if (selectedService?.serviceType == "Datalake") {
        databaseDetails["connection"] = {
          ...databaseDetails["connection"],
          datalake_type: formData?.DatalakeConfigSource,
        };
      }
    }
    try {
      const request_data = {
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(catalogData),
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        databaseDetails["service_id"] = response?.data?.id;
        await putDBConnectionDetails({databaseDetails,method,strapi_conn_ID: props?.strapi_conn_ID});
        showToast(`Database service ${request_data?.method == "PUT" ? "updated" : "added"} successfully`,"success","top-center");
        if (!props?.saveConn) {
          setTimeout(async () => {
            await ingestionPipelines({ data: response?.data });
            setLoadingCounter(0);
          }, 2000);
        }
      } else {
        setLoadingCounter(0);
        return null;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      await getDBList();
      setLoadingCounter(0);
    }
  };

  //get db connection details api
  const getDBConnectionDetails = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const dbdetails = {
        method: props?.id ? "DELETE" : "GET",
        url: props?.id
          ? `${constants.url.DBConnDetails}/${props?.id}`
          : `${constants.url.DBConnDetails}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const conn_res = await axios(dbdetails);
      if ([200, 201].includes(conn_res.status) && conn_res.data) {
        setLoadingCounter(0);
        if (!props?.id) {
          setConnData({
            data: conn_res?.data?.data?.map((e) => ({
              id: e?.id,
              ...e?.attributes,
            })),
            meta: conn_res?.data?.meta?.pagination,
          });
          return {
            data: conn_res?.data?.data?.map((e) => ({
              id: e?.id,
              ...e?.attributes,
            })),
            meta: conn_res?.data?.meta?.pagination,
          };
        }
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  // ingestion pipeline api
  const ingestionPipelines = async (props) => {
    let ingestionData;
    const randomChars = generateRandomString(6);
    const displayName = `${
      props?.data?.serviceType ?? props?.data?.service?.name
    }_Serive_metadata_${randomChars}`;

    ingestionData = {
      airflowConfig: {
        scheduleInterval: "0 */12 * * *",
        retries: 3,
      },
      loggerLevel: "INFO",
      name: displayName,
      displayName: displayName,
      pipelineType: props?.data?.pipelineType ?? "metadata",
      service: {
        id: `${props?.data?.service?.id ?? props?.data?.id}`,
        type: props?.data?.service?.type ?? "databaseService",
      },
      sourceConfig: {
        config: {
          type:
            props?.data?.service?.type === "messagingService"
              ? "MessagingMetadata"
              : "DatabaseMetadata",
          markDeletedTables: true,
          markDeletedStoredProcedures: true,
          includeTables: true,
          includeViews: true,
          includeTags: true,
          includeStoredProcedures: true,
          queryLogDuration: 1,
          queryParsingTimeoutLimit: 300,
          useFqnForFiltering: false,
        },
      },
    };

    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.ingestionPipelines}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(ingestionData),
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if(props?.showToast){
          showToast("Ingestion pipeline triggered", "success", "top-center");
        }
        setTimeout(async () => {
          await ingestionPipelinesDeploy(response?.data);
        }, 3500);
        setLoadingCounter(0);
      } else {
        setLoadingCounter(0);
        return null;
      }
    } catch (error) {
      showToast("Error while ingestion Pipelines", "error", "top-center");
      setLoadingCounter(0);
    }
  };

  // ingestion profiler pipeline api
  const ingestionProfilerPipelines = async (props,external = null) => {
    let ingestionData;
    const randomChars = generateRandomString(6);

    ingestionData = {
      airflowConfig: {
        scheduleInterval: "0 */12 * * *",
        retries: 3,
      },
      loggerLevel: "INFO",
      name: `${props?.serviceType ?? props?.service?.name}_Profiler_metadata_${randomChars}`,
      pipelineType: "profiler",
      service: {
        id: `${props?.service?.id ?? props?.id}`,
        type: props?.service?.type ?? "databaseService",
      },
      sourceConfig: {
        config: {
          "type": "Profiler",
          "includeViews": true,
          "processPiiSensitive": true,
          "confidence": 80,
          "profileSample": null,
          "profileSampleType": "PERCENTAGE",
          "generateSampleData": true,
          "computeMetrics": true,
          "sampleDataCount": 50,
          "threadCount": 5,
          "timeoutSeconds": 43200,
          "useFqnForFiltering": true
      },
      },
    };

    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.ingestionPipelines}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(ingestionData),
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if(external){
          showToast("Profiler pipeline triggered", "success", "top-center");
        }
        await profilerIngestionDeploy(response?.data);
        setLoadingCounter(0);
      } else {
        setLoadingCounter(0);
        return null;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  //Profiler ingestion Deploy
  const profilerIngestionDeploy = async (props, external = null) => {
    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.ingestionPipelinesDeploy}/` + `${props?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
          await profilerIngestionTrigger(props);
        setLoadingCounter(0);
        return response?.data
      } else {
        return null;
      }
    } catch (error) {
      setLoadingCounter(0);
        // await ingestionPipelinesDeploy(props, "external")
      // commonErrorShowPopup({ error });
    }
  };

    // profiler Ingestion trigger api
    const profilerIngestionTrigger = async (props) => {
      try {
        const request_data = {
          method: "POST",
          url: `${constants.url.ingestionPipelinesTrigger}/` + `${props?.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
        };
        const response = await axios(request_data);
        if ([200, 201].includes(response.status) && response.data) {
          if (serviceNameValue || props?.service?.name) {
            setTimeout(async () => {
              await getIngestionStatus({
                query: {
                  service: `${serviceNameValue ?? props?.service?.name}`,
                  fields: "pipelineStatuses",
                  pipelineType: "metadata,usage,lineage,profiler,dbt",
                },
                showToast: false,
              });
            }, 5000);
          }
          setLoadingCounter(0);
          return response?.data
        } else {
          return null;
        }
      } catch (error) {
        setLoadingCounter(0);
        setTimeout(async () => {
          await ingestionPipelinesDeploy(props, "external")
        },4000)
      }
    };

  // ingestion pipeline deploy api
  const ingestionPipelinesDeploy = async (props, external = null) => {
    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.ingestionPipelinesDeploy}/` + `${props?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        setTimeout(async () => {
          await ingestionPipelinesTrigger(props);
        }, 10000);
        if (external == null || external == "") {
          setTimeout(async () => {
            await ingestionProfilerPipelines(props);
          }, 15000);
        }
      } else {
        return null;
      }
    } catch (error) {
      showToast("Error while deploying pipeline", "error", "top-center");
      setLoadingCounter(0);
    }
  };

  // ingestion pipeline trigger api
  const ingestionPipelinesTrigger = async (props,showToast=null) => {
    try {
      const request_data = {
        method: "POST",
        url: `${constants.url.ingestionPipelinesTrigger}/` + `${props?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if (serviceNameValue || props?.service?.name) {
          setTimeout(async () => {
            await getIngestionStatus({
              query: {
                service: `${serviceNameValue ?? props?.service?.name}`,
                fields: "pipelineStatuses",
                pipelineType: "metadata,usage,lineage,profiler,dbt",
              },
              showToast: false,
            });
          }, 5000);
        }
        if(showToast) {
          showToast("Pipeline triggered successfully","success", "top-center");
        }
        setLoadingCounter(0);
        return response?.data
      } else {
        return null;
      }
    } catch (error) {
      setLoadingCounter(0);
      setTimeout(async () => {
        await ingestionPipelinesDeploy(props, "external")
      },4000)
    }
  };

  // get ingestion status  api
  const getIngestionStatus = async (props) => {
    try {
      const request_data = {
        method: "GET",
        url:
          `${constants.url.ingestionPipelines}` +
          generateQueryString(props?.query),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        const connection_status = response?.data?.data.map(
          (item) => item?.pipelineStatuses?.pipelineState
        );
        if (props?.showToast) {
          showToast(`ingestion pipelines triggered ${connection_status}`,
            connection_status == "failed" ? "error" : connection_status == "running" ? "warn" : "success","top-center");
        }
        setLoadingCounter(0);
        return response?.data;
      } else {
        setLoadingCounter(0);
        return null;
      }
    } catch (error) {
      showToast(error, "error", "top-center");
      setLoadingCounter(0);
    }
    setLoadingCounter(0);
  };

  // get classification tags
  const Classifications = async(props) => {
    if(props?.loader){
      setLoadingCounter((prevCounter) => prevCounter + 1);
      try {
        const test_obj = {
          method: props?.method,
          url: props?.url,
          headers: {
            "Content-Type": props?.method == "PATCH" ? "application/json-patch+json" : "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
          ...(props?.method?.toUpperCase() !== 'GET' && { data: JSON.stringify(props?.data) }),
        };
        const response = await axios(test_obj);
        if ([200, 201].includes(response.status) && response.data) {
          setLoadingCounter(0);
          if(props?.toastMsg){
            showToast(props?.toastMsg, "success", "top-center");
          }
          return response;
        }
      } catch (error) {
        console.log("error--->>",error)
        setLoadingCounter(0);
        commonErrorShowPopup({ error });
      }
    }
  };

  // get classification tags
  const getTags = async (props) => {
    if(props?.loader){
      setLoadingCounter((prevCounter) => prevCounter + 1);
      try {
        const test_obj = {
          method: props?.method,
          url: props?.url,
          headers: {
            "Content-Type": props?.method == "PATCH" ? "application/json-patch+json" : "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
          ...(props?.method?.toUpperCase() !== 'GET' && { data: JSON.stringify(props?.data) }),
        };
        const response = await axios(test_obj);
        if ([200, 201].includes(response.status) && response.data) {
          setLoadingCounter(0);
          return response;
        }
      } catch (error) {
        setLoadingCounter(0);
        commonErrorShowPopup({ error });
      }
    }
  };

  //PII Tags patch api
  const PIITagsappend = async (props) => {
    const { val, id, tags, index } = props;
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    let payload;
    if (tags?.length > 0) {
      const tagFQNs = tags?.map((tag) => tag.tagFQN);
      const matchingVals = val?.filter((val) =>
        tagFQNs.some((tagFQN) => tagFQN.startsWith(val?.classification))
      );

      if (tags?.length > 0 && tags?.length > val?.length) {
        if (val?.length > 0) {
          const valTagFQNs = val?.map((item) => item.value);
          const missingTags = tags
            .map((tag, idx) => ({ tag, idx }))
            .filter(({ tag }) => !valTagFQNs.includes(tag.tagFQN));
          payload = missingTags?.map(({ tag, idx }) => ({
            op: "remove",
            path: `/columns/${index}/tags/${idx}`,
          }));
        } else {
          payload = tags?.map((item, idx) => ({
            op: "remove",
            path: `/columns/${index}/tags/${idx}`,
          }));
        }
      } else if (matchingVals?.length > 0) {
        val?.forEach((valItem, index) => {
          if (tagFQNs?.some((tagFQN) => tagFQN?.startsWith(valItem?.classification))) {
            val?.splice(index, 1); // Delete the element at the current index
          }
        });
        payload = val?.map((item, idx) => ({
          op: "add",
          path: `/columns/${index}/tags/${idx}`,
          value: {
            tagFQN: `${item?.value}`,
            source: "Classification",
            labelType: "Manual",
            name: item?.label,
            description: "PII which if lost, compromised, or disclosed without authorization, could result in substantial harm, embarrassment, inconvenience, or unfairness to an individual.",
            style: {},
            state: "Confirmed",
          },
        }));
      }
    } else {
      payload = props?.val?.map((item, idx) => ({
        op: "add",
        path: `/columns/${index}/tags/${idx}`,
        value: {
          tagFQN: `${item?.value}`,
          source: "Classification",
          labelType: "Manual",
          name: item?.label,
          description: "PII which if lost, compromised, or disclosed without authorization, could result in substantial harm, embarrassment, inconvenience, or unfairness to an individual.",
          style: {},
          state: "Confirmed",
        },
      }));
    }

    try {
      const request_data = {
        method: "PATCH",
        url: `${constants.url.getTables}/` + `${id}`,
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(payload),
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        showToast(`PII Tag ${payload[0]?.op} successfully`,"success","top-center");
        setLoadingCounter(0);
        if (loader) {
          setLoadingCounter((prevCounter) => prevCounter - 1);
          loader = false;
        }
        return response;
      } else {
        return null;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({error});
      console.log("errrorrrrr===>", error);
    }
  };

  const deleteTags = async(props) =>{
    let loader = false;
    if (props?.loader !== false) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
      loader = true;
    }
    try {
      const test_obj = {
        method: props?.method,
        url: `${constants.url.getTables}/` + `${props?.column_id}`,
        headers: {
          "Content-Type": props?.method == "PATCH" ? "application/json-patch+json" : "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        ...(props?.method?.toUpperCase() !== 'GET' && { data: JSON.stringify(props?.data) }),
      };
      const response = await axios(test_obj);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        showToast(`PII Tag deleted successfully`,"success","top-center");
        return response;
      }
    } catch (error) {
      console.log("delete tag error--->>",error)
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  }

  //Lineage Data
  const LineageData = async (props) => { 
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try { 
      const lineage_obj = {
        method: "POST",
        url: `http://35.233.222.95/getlineage`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          Dataset: modifyString(props),
          omd_url: userConnectionData[0]?.OMD_URL,
          ol_url: userConnectionData[0]?.OL_URL,
          token: `${getLocalVariables("ADMIN_TOKEN")}`,
        }
      };
      const response = await axios(lineage_obj);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //Testcase Data
  const TestcaseData = async (props) => {
    if(props?.loader){
      setLoadingCounter((prevCounter) => prevCounter + 1);
      try {
        const test_obj = {
          method: "GET",
          url: `${constants.url.TestcaseUrl}` + props?.name,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
        };
        const response = await axios(test_obj);
        if ([200, 201].includes(response.status) && response.data) {
          setLoadingCounter(0);
          return response?.data;
        }
      } catch (error) {
        setLoadingCounter(0);
        commonErrorShowPopup({ error });
      }
    }
  };

  //PATCH testcase
  const PatchTestcaseData = async (props) => {
    if(props?.loader){
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
      try {
        const test_obj = {
          method: "PATCH",
          url: `${constants.url.TestcaseUrl}` + props?.name,
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
          data: props?.payload
        };
        const response = await axios(test_obj);
        if ([200, 201].includes(response.status) && response.data) {
          setLoadingCounter(0);
          return response?.data;
        }
      } catch (error) {
        setLoadingCounter(0);
        commonErrorShowPopup({ error });
      }
  };

  //get data quality
  const getDataQuality = async (props) => {
    if(props?.loader){
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
      try {
        const test_obj = {
          method: "GET",
          url: `${constants.url.getDataQuality}` + props?.name,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
        };
        const response = await axios(test_obj);
        if ([200, 201].includes(response.status) && response.data) {
          setLoadingCounter(0);
          return response?.data;
        }
      } catch (error) {
        setLoadingCounter(0);
        commonErrorShowPopup({ error });
      }
  };

  //Registration api
  const onRegister = async (values) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const user_reg = {
        method: "POST",
        url: `${constants.url.registration}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          username: values.user,
          email: values.email,
          password: values.password,
          enterprise: {
            name: values.user,
            email: values?.email,
          },
        },
      };
      const login_response = await axios(user_reg);
      if ([200, 201].includes(login_response.status) && login_response.data) {
        showToast("Registered successfully", "success", "top-center");
        setOpenSidebar(false);
        setLoadingCounter(0);
        navigate(constants.route.Login);
        return login_response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  // resend confirm email api
  const resendEmail = async (props) => {
    try {
      axios
        .post(constants.url.resendEmail, { email: props?.email })
        .then((response) => {
          if (response) {
            showToast("Check your email to verify your account.","success","top-center");
          }
        })
        .catch((error) => {
          commonErrorShowPopup({ error });
        });
    } catch (error) {
      commonErrorShowPopup({ error });
    }
  };

  //user role api
  const userRole = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.userMe}?${userMeQuery()}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        if (!elements_response?.data?.confirmed) {
          localStorage.clear();
          navigate(constants?.route?.Login);
        }
        setLoadingCounter(0);
        const getProviderType = (type) => {
          return (
            elements_response?.data?.enterprise?.cloud_account_settings?.find(
              (i) => i?.provider_type == type
            ) ?? []
          );
        };
        setLocalVariables("USER_DATA", {
          id: elements_response?.data?.id,
          username: elements_response?.data?.username,
          email: elements_response?.data?.email,
          role: elements_response?.data?.role,
          enterprise: elements_response?.data?.enterprise?.id,
          ...(getProviderType("azure")
            ? { azure: getProviderType("azure")?.id }
            : {}),
          ...(getProviderType("jupyter_lab")
            ? { jupyter_lab: getProviderType("jupyter_lab")?.id }
            : {}),
          ...(getProviderType("external_db")
            ? { external_db: getProviderType("external_db")?.id }
            : {}),
          cloud_account_settings:
            elements_response?.data?.enterprise?.cloud_account_settings,
          last_name: elements_response?.data?.last_name,
          company_name: elements_response?.data?.enterprise?.name,
          website: elements_response?.data?.enterprise?.website,
          contact_number: elements_response?.data?.enterprise?.contact_number,
        });
        return elements_response.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  const updateActiveLink = async (props) => {
    try {
      const token = getLocalVariables("ADMIN_TOKEN") ?? props?.token;
      const payload = {
        jwt_token: token,
        id: props?.id,
      };
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.updateActiveLink}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        if(props?.showMsg){
          showToast("Request sent successfully", "success", "top-center");
        }
        return elements_response;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //get OpenMetadata Token
  const getOpenMetadataToken = async (props) => {
    try {
      const getToken = {
        method: "GET",
        url: constants.url.getOpenMetadataToken,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            props?.token ?? getLocalVariables("ADMIN_TOKEN")
          }`,
        },
      };
      const response = await axios(getToken);
      if ([200, 201].includes(response?.data?.status) && response?.data?.token) {
        setLocalVariables("OPENMETADATA_TOKEN", response?.data?.token);
      }
    } catch (error) {}
  };

  // Login api
  const onLogin = async (values) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const login_req_obj = {
        method: "POST",
        url: constants.url.login,
        headers: { "Content-Type": "application/json"},
        data: {
          identifier: values.email,
          password: values.password,
        },
      };
      const login_response = await axios(login_req_obj);
      if ([200, 201].includes(login_response.status) && login_response.data) {
        showToast("Logged in successfully", "success", "top-center");
        await getOpenMetadataToken({ token: login_response.data.jwt });
        await userRole({ token: login_response.data.jwt });
        setLocalVariables("ADMIN_TOKEN", login_response.data.jwt);
        setLoadingCounter(0);
        return login_response;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      if (
        error?.response?.data?.error?.status == 400 &&
        error?.response?.data?.error?.message ==
          "Your account email is not confirmed"
      ) {
        setLocalVariables("USER_DATA", {
          ...getLocalVariables("USER_DATA"),
          timer: 60,
        });
        navigate(constants.route.verification);
        resendEmail({ email: values.email });
      }
      setLoadingCounter(0);
    }
  };

  const checkAdminLoginStatus = () => {
    const admin_token = getLocalVariables("ADMIN_TOKEN");
    if (admin_token && admin_token.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkSuperAdminExist = async (props) => {
    try {
      if ((!checkAdminLoginStatus() || props?.runonce) && pagePath !== "/confirm") {
        setLoadingCounter((prevCounter) => prevCounter + 1);
        const request_data = {
          method: "GET",
          url: constants.url.checkSuperAdminExist,
          headers: {"Content-Type": "application/json"},
        };
        const response = await axios(request_data);
        if ([200, 201].includes(response?.data?.status) && response.data) {
          setLoadingCounter(0);
          setLocalVariables("SUPER_ADMIN_EXIST", response?.data);
          if (response?.data?.data > 0) {
            navigate(constants.route.Login);
          } else if (response?.data?.data == null) {
            navigate(constants.route.Signup);
          }
          return response?.data;
        }
      }
    } catch (error) {
      console.error("error in check super admin exist api", error);
      setLoadingCounter(0);
    }
  };

  // get total count of tables and columns
  const getDashboardCount = async () => {
    try {
      let tablesCount = 0;
      let connectionsCount = 0;
      let schemasCount = 0;
      let databsesCount = 0;
      let tagsCount = 0;
      let storedProcedureCount = 0;
      const request_data = {
        method: "GET",
        url: constants.url.dashboards,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response?.status) && response.data) {
        const result = response?.data?.aggregations;

        //getting tables and connections count
        if (result && result["sterms#entityType"]?.buckets?.length > 0) {
          result &&
            result["sterms#entityType"]?.buckets?.forEach((bucket) => {
              if (bucket.key === "table") {
                tablesCount = bucket?.doc_count;
              }
              if (bucket.key === "databaseSchema") {
                schemasCount = bucket?.doc_count;
              }
              if (bucket.key === "database") {
                databsesCount = bucket?.doc_count;
              }
              if (bucket.key === "storedProcedure") {
                storedProcedureCount = bucket?.doc_count;
              }
              if (bucket.key === "tag") {
                tagsCount = bucket?.doc_count;
              }
              if (
                bucket.key === "databaseService" ||
                bucket.key === "messagingService"
              ) {
                connectionsCount = connectionsCount + bucket?.doc_count;
              }
            });
            setDictionaryDataBckp(response?.data?.hits?.hits);
            return {connections: connectionsCount,databases: databsesCount,tags: tagsCount, tables: tablesCount,storedProcedures: storedProcedureCount, schemas: schemasCount,status: 201}
          }
        setLoadingCounter(0);
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //get data dictionary api
  const getDatadictionary = async (props) => {
    if (props?.loader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      if(props?.search_index){
        const request_data = {
          method: "GET",
          url: process.env.REACT_APP_META_HOST_URL + `/v1/search/query?q=&index=${props?.search_index}_search_index&from=0&size=10000&deleted=false&query_filter={"query":{"bool":{}}}&sort_field=${props?.search_index == "tag" ? "name.keyword" : "totalVotes"}&sort_order=asc&field=testSuite`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
        };
        const response = await axios(request_data);
        if ([200, 201].includes(response?.status) && response?.data?.hits) {
          setLoadingCounter(0);
          return response?.data
        }
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  //search dictionary data api
  const getSearchDictionary = async (props) => {
    if (props?.loader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
        const request_data = {
          method: "GET",
          url: process.env.REACT_APP_META_HOST_URL + `/v1/search/query?q=${props?.search}&from=0&size=10000&index=dataAsset`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
          },
        };
        const response = await axios(request_data);
        if ([200, 201].includes(response?.status) && response?.data?.hits) {
          setLoadingCounter(0);
          return response?.data?.hits?.hits
        }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  const getHistory = async (props) => {
    try {
      const request_data = {
        method: "GET",
        url: props
          ? constants.url.history + `?${props?.query}`
          : constants.url.history,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        setHistoryData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  const CreateSetting = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "POST",
        url: constants.url.SettingDb,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props?.payload }),
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setTimeout(() => {
          getTableSetting({ query: settingsQuery() });
        }, 1000);
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  const getTableSetting = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.SettingDb + `?${props?.query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        setSettingTableData(response?.data);
        return response?.data;
      }
    } catch (error) {
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  };

  const deleteSetting = async (id) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.SettingDb}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        showToast("Deleted successfully", "success", "top-center");
        setTimeout(async () => {
          getTableSetting({ query: settingsQuery() });
        }, 1000);
        setLoadingCounter(0);
      }
    } catch (error) {
      setLoadingCounter(0);
      commonErrorShowPopup({ error });
    }
  };

  const getUserPermission = async (props) => {
    const query = qs.stringify(
      {
        sort: "createdAt:desc",
        ...(props?.limit
          ? {
              pagination: {
                start: 0,
                limit: props?.limit,
              },
            }
          : {
              pagination: {
                page: props?.page,
                pageSize: props?.rowsPerPage,
              },
            }),
        ...(props?.search && {
          filters: {
            email: {
              $contains: props?.search,
            },
          },
        }),
        ...(props?.email && {
          filters: {
            email: {
              $eqi: props?.email,
            },
          },
        }),
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.user_permission + `?${props?.query ?? query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN") ?? props.TOKEN
          }`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setUserPermission({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        return {
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        };
      }
    } catch (error) {
      commonErrorShowPopup({ error });
    }
  };

  const getCloudAccountSettings = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.cloudAccountSettings,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props?.token ?? getLocalVariables("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        return response?.data;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  //Data Quality api starts
  const DQExecutable = async (props) => {
    if (props?.loader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      const request_data = {
        method: "POST",
        url: constants.url.DQExecutable,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(props?.payload)
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        return response;
      }
    } catch (error) {
      setLoadingCounter(0);
      return error
    }
  };

  const DQtestCase = async (props) => {
    let loading = false;
    if (props?.loader) {
      loading = true;
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      const request_data = {
        method: "POST",
        url: constants.url.TestcaseUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(props?.payload)
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if (loading) {
          loading = false;
          setLoadingCounter(0);
        }
        return response;
      }
    } catch (error) {
      setLoadingCounter(0);
      return error
    }
  };

  const DQtestCaseIngestion = async (props) => {
    try {
      const ingestion = {
        "airflowConfig": { "scheduleInterval": "0 * * * *"},
        "name": props?.data?.entityFQN?.split(".").pop() + "_TestSuite",
        "pipelineType": "TestSuite",
        "service": { "id": props?.data?.testSuite?.id,"type": "testSuite"},
        "sourceConfig": {"config": {"type": "TestSuite","entityFullyQualifiedName": props?.data?.entityFQN}}
    }
      const request_data = {
        method: "POST",
        url: constants.url.ingestionPipelines,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
        data: JSON.stringify(ingestion)
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        return response;
      }
    } catch (error) {
      setLoadingCounter(0);
      return error
    }
  };

  const DQtestCaseIngestionFunc = async (props) => {
    if (props?.loader) {
      setLoadingCounter((prevCounter) => prevCounter + 1);
    }
    try {
      const request_data = {
        method: "POST",
        url: `${props?.endpoint}/` + `${props?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("OPENMETADATA_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        return response;
      } else {
        return null;
      }
    } catch (error) {
      setLoadingCounter(0);
    }
  };

  const updateUserConnection=async(props)=>{
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const elements_req_obj = {
        method: props?.method,
        url: constants.url.userConnections,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalVariables("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({data : {...props}}),
      };
      if(props?.method == "GET"){
        delete elements_req_obj?.data;
      }
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response?.status) && elements_response?.data) {
        if (Array.isArray(elements_response?.data?.data)) {
          const result = elements_response?.data?.data?.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          }))
          setUserConnectionData(result);
          setLoadingCounter(0);
          return elements_response;
        }
        if(props?.method == "POST"){
          setLoadingCounter(0);
          navigate(constants.route.Dashboard);
        }
      }
    } catch (error) {
      console.log("Error while updateUserConnection===>>",error)
      commonErrorShowPopup({ error });
      setLoadingCounter(0);
    }
  }

  const OpenMetadataAuth = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const signinUrl = props?.openmetaurl + `/api/v1/users/login`;
      const loginData = {
        email: props?.username,
        password: btoa(props?.password)
      };
      const response = await axios.post(signinUrl, loginData);
      setLoadingCounter(0);
      return response;
    } catch (error) {
      setLoadingCounter(0);
      console.error("Error logging in to OpenMetadata:", error);
      return error;
    }
  };

  const LineageAuth = async (props) => {
    setLoadingCounter((prevCounter) => prevCounter + 1);
    try {
      const request_data = {
        method: "GET",
        url: props?.lineageurl + '/api/v1/events/lineage',
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLoadingCounter(0);
        return response;
      }
    } catch (error) {
      setLoadingCounter(0);
      console.error("Error logging in to lineage:", error);
      return error;
    }
  };

  // set default fields open
  useEffect(() => {
    dbList?.forEach((data) => {
      data?.connection?.config?.fields?.forEach((field) => {
        if (field.type === "dropdown" && field.defaultValue) {
          handleChange({
            target: { name: field.name, value: field.defaultValue },
          });
        } else if (field.type === "input" && field.defaultValue) {
          handleChange({
            target: { name: field.name, value: field.defaultValue },
          });
          setFormData((prevFormData) => ({
            ...prevFormData,
            [field.name]: field.defaultValue,
          }));
        }
      });
    });
  }, [dbList]);

  return (
    <Context.Provider
      value={{
        search,
        setSearch,
        currentStep,
        setCurrentStep,
        serviceList,
        setServiceList,
        selectedCard,
        setSelectedCard,
        selectedService,
        setSelectedService,
        serviceNameValue,
        setServiceNameValue,
        formData,
        setFormData,
        saveDBService,
        tableData,
        getDBList,
        dbList,
        descriptionVal,
        setDescriptionVal,
        totalItems,
        page,
        previousPage,
        setPreviousPage,
        rowsPerPage,
        setPage,
        loadingCounter,
        policyData,
        GetPolicies,
        setIsPopupOpen,
        isPopupOpen,
        RenderFields,
        userData,
        getRegisteredTableData,
        fetchUserData,
        getGroup,
        createGroup,
        putGroup,
        deleteGroup,
        createUser,
        putUser,
        setPolicyData,
        groupData,
        getAllCatalogs,
        getCatalogs,
        getEditPolicy,
        editPolicy,
        putPolicy,
        deletePolicy,
        createPolicy,
        customNamespace,
        getCustomNamespace,
        getTables,
        metaTableData,
        getColumnsData,
        columnsData,
        deleteUser,
        CheckTestConnection,
        deleteSource,
        setLoadingCounter,
        LineageData,
        TestcaseData,
        deleteTestcase,
        setSelectedCatalogDetails,
        selectedCatalogDetails,
        setIsSidebarOpen,
        isSidebarOpen,
        onRegister,
        resendEmail,
        onLogin,
        checkAdminLoginStatus,
        getDashboardCount,
        setOpenSidebar,
        openSidebar,
        CreateSetting,
        getTableSetting,
        deleteSetting,
        settingTableData,
        getDBConnectionDetails,
        ingestionPipelines,
        getIngestionStatus,
        ingestionPipelinesDeploy,
        ingestionPipelinesTrigger,
        deleteIngestionPipeline,
        setRefresh,
        refresh,
        connData,
        getUserPermission,
        userPermission,
        getCloudAccountSettings,
        commonErrorShowPopup,
        messagingServices,
        getMessagingServices,
        getTopics,
        getTopicName,
        userRole,
        ingestionProfilerPipelines,
        PIITagsappend,
        getColumnTags,
        createClassification,
        createTag,
        getHistory,
        historyData,
        updatePasswordByUser,
        connectionSteps,
        checkSuperAdminExist,
        getOpenMetadataToken,
        getDatadictionary,
        getSearchDictionary,
        dictionaryDataBckp,
        DQExecutable,
        DQtestCase,
        DQtestCaseIngestion,
        DQtestCaseIngestionFunc,
        OpenMetadataAuth,
        LineageAuth,
        updateUserConnection,
        userConnectionData,
        getDataQuality,
        PatchTestcaseData,
        updateActiveLink,
        Classifications,
        getTags,
        deleteTags
      }}
    >
      {children}
      <Toastify />
    </Context.Provider>
  );
};

export default Context;
