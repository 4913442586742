import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale("en");
dayjs.extend(relativeTime);

export const copyDict = (dict) => {
  return JSON.parse(JSON.stringify(dict));
};

export const dateFormat = (date) => {
  return date ? dayjs(date).locale("en").fromNow() : "-";
};

export const isJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const capitalizeFirstLetter = (string) => {
  const words = string?.split(/[\s_]+/)?.join(" ");
  const str = words?.toLowerCase();
  if (typeof str !== "string" || str?.length === 0) {
    return str;
  }
  if (/^[a-z]/.test(str[0])) {
    return str[0]?.toUpperCase() + str?.slice(1);
  } else {
    return str;
  }
};

export const capitalizeFirstLetterOfEachWord = (text) => {
  const words = text?.split(/[\s_]+/);
  const capitalizedWords = words?.map((word) => {
    const firstLetter = word?.charAt(0).toUpperCase();
    const restOfWord = word?.slice(1);
    return `${firstLetter}${restOfWord}`;
  });
  return capitalizedWords?.join(" ");
};

export const filterDataBySearch = (data, search) => {
  return _.filter(data, (item) =>
    Object.values(item).some((value) => !search ? true : value?.toString()?.toLowerCase()?.includes(search?.toLowerCase()))
  );
};

export function searchByFilter(data, searchText) {
  // Ensure searchText is a string and trimmed
  if (searchText?.trim()) {
    const query = searchText.trim().toLowerCase();
    
    // Return filtered data based on the name matching
    return data?.filter(item => {
      const name = item?.name?.toLowerCase();
      return name?.includes(query);
    });
  }
  
  // If searchText is null, undefined, or empty, return the original data
  return data;
}

export const maskValue = (value) => {
  if (value) {
    const length = value?.length;
    const start = length > 8 ? value?.substring(0, 4) : value?.substring(0, 3);
    const end =
      length > 8 ? value?.substring(length - 4) : value?.substring(length - 3);
    const maskedMiddle = "*".repeat(Math.max(0, length - 6));
    return `${start}${maskedMiddle}${end}`;
  } else {
    return "";
  }
};

export const transformString = (inputString) => {
  const words = inputString?.split("_");
  const capitalizedWords = words?.map((word) => {
    return word.charAt(0).toUpperCase() + word?.slice(1);
  });
  return capitalizedWords?.join(" ");
};

export const filterArrayByKeys = (dataArray, cardData) => {
  return dataArray
    ?.map((k) => ({ k, value: cardData[k] }))
    ?.filter((item) => item?.value);
};

export const generateQueryString = (parameters) => {
  if (!parameters) return "";
  const queryString = Object.entries(parameters)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const convertToSnakeCase = (inputString) => {
  return inputString.toLowerCase().replace(/ /g, "_");
};

export const getCookie = (name) => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

/*
const headers = new Headers({
  "Cache-Control": "no-cache, no-store, must-revalidate",
  "Pragma": "no-cache",
  "Expires": "0",
  "Authorization": getJupyterToken(),
});
*/
// export const createServerSettings = (baseUrl, wsUrl, token) => {
//   return ServerConnection.makeSettings({
//     baseUrl,
//     wsUrl,
//     token,
//     appendToken: true,
//     init: {
//       mode: "cors",
//       credentials: "include",
//       cache: "no-cache",
//       // headers,
//     },
//   });
// };

export const setSessionVariables = (key, value) => {
  if (key) {
    try {
      if (value === null) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(
          key,
          btoa(
            encodeURIComponent(
              JSON.stringify(isObject(value) ? value ?? {} : value ?? "")
            )
          )
        );
      }
    } catch (error) {
      throw new Error("Error storing data in sessionStorage: " + error);
    }
  }
};

export const getNextExecutionCount = (cells) => {
  let highestExecutionCount = null;

  for (const count of cells) {
    if (count.cell_type === "code" && count.execution_count !== null) {
      const executionCount = count.execution_count;
      if (
        highestExecutionCount === null ||
        executionCount > highestExecutionCount
      ) {
        highestExecutionCount = executionCount;
      }
    }
  }

  return highestExecutionCount ? highestExecutionCount + 1 : 1;
};

export const getFileType = (view) => {
  let type = "";
  switch (true) {
    case view?.includes(".ipynb"):
      type = "editor";
      break;
    case view?.includes(".json"):
      type = "json_viewer";
      break;
    case view?.includes(".py"):
      type = "py";
      break;
    default:
      type = "txt";
      break;
  }
  return type;
};

export const generateRandomNumber = (length) => {
  const min = 10 ** (length - 1);
  const max = 10 ** length - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getExecutionTime = (startDateTime, endDateTime) => {
  const startTime = dayjs(startDateTime);
  const endTime = dayjs(endDateTime);
  const executionTime = dayjs.duration(endTime.diff(startTime));

  let formattedExecutionTime = "";

  const minutes = executionTime.minutes();
  const seconds = executionTime.seconds();

  if (minutes > 0) {
    formattedExecutionTime += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  if (seconds > 0) {
    if (formattedExecutionTime !== "") {
      formattedExecutionTime += " and ";
    }
    formattedExecutionTime += `${seconds} second${seconds > 1 ? "s" : ""}`;
  }

  return formattedExecutionTime;
};

export const convertDataToDesiredFormat = (data) => {
  if (data) {
    const keysOrder = data?.key;
    const transformedData = data?.value?.map((item) => {
      return keysOrder?.reduce((obj, key, index) => {
        obj[key] = item[index];
        return obj;
      }, {});
    });
    return transformedData;
  } else {
    return [];
  }
};

export const truncateString = (str, maxLength) => {
  if (str?.length <= maxLength) {
    return str;
  } else {
    return str?.substring(0, maxLength) + "...";
  }
};

export const extractValues = (q) => {
  const regex = /{{(.*?)}}/g;
  const found = [];
  let match;
  /* eslint-disable no-cond-assign */
  while ((match = regex.exec(q)) !== null) {
    found.push(match[1]);
  }

  return found;
};

export const checkArrayValuesInObject = (array, object) => {
  const allValuesPresent = array.every((value) =>
    Object.prototype.hasOwnProperty.call(object, value)
  );

  return allValuesPresent;
};

export const isPercentage = (val) =>
  typeof val === "string" && val.indexOf("%") > -1;

export const percentToPx = (value, comparativeValue) => {
  if (value?.indexOf("px") > -1 || value === "auto" || !comparativeValue)
    return value;
  /* eslint-disable radix */
  const percent = parseInt(value);
  return (percent / 100) * comparativeValue + "px";
};
export const pxToPercent = (value, comparativeValue) => {
  const val = (Math.abs(value) / comparativeValue) * 100;
  if (value < 0) return -1 * val;
  else return Math.round(val);
};
export const getElementDimensions = (element) => {
  const computedStyle = getComputedStyle(element);

  let height = element.clientHeight,
    width = element.clientWidth; // width with padding

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    width,
    height,
  };
};

export const isPrivateDomain = (email) => {
  const publicDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "protonmail.com",
  ]; // Add more public domains as needed
  const domain = email.split("@")[1];
  return !publicDomains.includes(domain);
};

export const weightDescription = (weight) =>
  weight === 400 ? "Regular" : weight === 500 ? "Medium" : "Bold";

export const isObject = (value) => {
  return value !== null && typeof value === "object";
};

export const setLocalVariables = (key, value) => {
  if (key) {
    try {
      if (value === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(
          key,
          btoa(
            encodeURIComponent(
              JSON.stringify(isObject(value) ? value ?? {} : value ?? "")
            )
          )
        );
      }
    } catch (error) {
      throw new Error("Error storing data in localStorage: " + error);
    }
  }
};

export const getLocalVariables = (key) => {
  if (key) {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(decodeURIComponent(atob(data)));
      } catch (error) {
        throw new Error("Error parsing data from localStorage: " + error);
      }
    }
  }
  return null;
};

export function formatRelativeTime(timestamp) {
  const timestampInSeconds = timestamp / 1000;
  const date = new Date(timestampInSeconds * 1000);
  const timeDifferenceInSeconds = Math.floor((Date.now() - date) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    const intervalCount = Math.floor(timeDifferenceInSeconds / secondsInUnit);
    if (intervalCount > 0) {
      return `${intervalCount} ${unit}${intervalCount === 1 ? "" : "s"} ago`;
    }
  }

  return "just now";
}

// Generate a random string of characters
export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

//fetch database name 
export const fetchDataBaseName = (data) => {
  const findDatabaseName = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        const dbName = findDatabaseName(obj[key]);
        if (dbName) return dbName;
      } else if (key === "database" || key === "databaseName" || key === "catalog") {
        return obj[key];
      }
    }
    return null;
  };

  let dbName = null;
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((item) => {
      dbName = findDatabaseName(item.connection.config);
      if (dbName) return dbName; // Exit loop if dbName is found
    });
  }
  return dbName;
};

export function removeFirstValue(fqName) {
  const index = fqName.indexOf('.');
  if (index === -1) {
    // If there's no dot, return the original string
    return fqName;
  }
  // Return the substring starting from the character after the first dot
  return fqName.substring(index + 1);
}

export const modifyString = (str) => {
  const index = str?.indexOf('.');
  if (index !== -1) {
    return str?.slice(0, index) + str?.slice(str?.indexOf('.', index + 1));
  }
  return str;
};