import React from "react";
import { Form, Input, Popconfirm, Tooltip, message } from "antd";
import "../index.css"

const InputV2 = ({
  label,
  type,
  editable = false,
  disabled = false,
  defaultValue,
  handleFieldEdit = () => {},
  onChange = () => {},
  addonBefore,
  value,
  placeholder,
  suffix,
  prefix,
  allowClear,
  copyable,
  copyText,
  ...rest
}) => {
  const handleNumberInput = (e) => {
    if (type === "number") {
      const allowedChars = /^[0-9.-]+$/;
      if (!allowedChars.test(e.target.value)) {
        e.target.value = e.target.value.replace(/[^0-9.-]/g, "");
      }
    }
    onChange(e);
  };
  return (
    <Form.Item label={label?.toUpperCase()} {...rest}>
      {type === "password" ? (
        <Input.Password />
      ) : (
        <Input
          type={type}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          onChange={handleNumberInput}
          addonBefore={addonBefore}
          placeholder={placeholder}
          prefix={prefix}
          allowClear={allowClear}
          suffix={
            editable ? (
              <Tooltip title="Edit">
                <i
                  className={`${disabled ? "fa-regular fa-pen-to-square" : "fa-solid fa-check"} cursor-pointer text-gray-600 hover:text-inputOrange`}
                  aria-hidden="true"
                  onClick={handleFieldEdit}
                />
              </Tooltip>
            ) : copyable ? (
              <Popconfirm
                open={false}
                onOpenChange={() => {
                  if (value || defaultValue) {
                    message.success("copied!");
                  }
                }}
              >
                <i
                  className="fa-solid fa-copy text-00000073 hover:text-inputOrange text-xs ml-2 p-1 cursor-pointer"
                  role="presentation"
                  onClick={() => navigator.clipboard.writeText(copyText)}
                />
              </Popconfirm>
            ) : (
              suffix
            )
          }
        />
      )}
    </Form.Item>
  );
};

export default React.memo(InputV2);
